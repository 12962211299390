body {
  font-family: sans-serif;
}

#c {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}
